<template>
  <section class="l-content">
    <el-row>
      <el-col :span="12">
        <el-card class="margin-xs" :body-style="cardBodyStyle">
          <div slot="header" class="clearfix">
            <i class="el-icon-s-operation"></i>
            <span> 企业列表</span>
          </div>
          <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
            <template slot="enterpriseName" slot-scope="scope">
              <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
            </template>
          </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="margin-xs" :body-style="cardBodyStyle">
          <div slot="header" class="clearfix">
            <el-row type="flex" justify="space-between">
              <el-col
                ><i class="el-icon-s-operation"></i>
                <span> Web菜单权限</span>
              </el-col>
              <el-button v-if="selEnterprise" type="primary" size="mini" @click="saveWebMenu">保存</el-button>
            </el-row>
          </div>
          <el-alert v-if="selEnterprise" :title="selEnterprise.enterpriseName" type="success" effect="dark" :closable="false" class="margin-bottom-xs"> </el-alert>
          <el-tree v-show="selEnterprise" :data="appList" show-checkbox ref="webMenuTree" node-key="fullCode" node-la default-expand-all :props="{ label: 'appName' }"> </el-tree>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'EnterpriseMenu',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '企业编号', prop: 'enterpriseCode', width: 80 },
          { label: '企业名称', slot: 'enterpriseName' }
        ],
        operation: {
          width: '220',
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '菜单权限', click: this.enterpriseSel }]
        }
      },
      cardBodyStyle: {
        overflow: 'auto',
        height: document.documentElement.clientHeight - 200 + 'px'
      },
      condition: {
        keyword: '',
        pageIndex: 1,
        pageSize: 50
      },
      selEnterprise: null,
      pageData: null,
      appList: null
    }
  },
  created() {
    this.getPageData()
    this.getAppList()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/list', this.condition)
    },
    getAppList() {
      this.$lgh.get('api/comm/app/list').then(res => {
        this.appList = res
      })
    },
    enterpriseSel(item) {
      this.selEnterprise = item
      this.$refs.webMenuTree.setCheckedKeys(item.appIdsList)
    },
    saveWebMenu() {
      var list = this.$refs.webMenuTree.getCheckedKeys()
      this.$lgh.msgConfirm('确认修改?', async () => {
        await this.$lgh.post('api/enterprise/update/appids', {
          enterpriseCode: this.selEnterprise.enterpriseCode,
          appIdsList: list
        })
        this.selEnterprise.appIdsList = list
        this.$lgh.notifySuccess('修改成功', this.selEnterprise.enterpriseName + '菜单权限信息已修改')
      })
    }
  }
}
</script>
